import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Learning to Create Video Games",
  "date": "2019-05-30T13:00:00.000Z",
  "layout": "post",
  "draft": false,
  "path": "/posts/learning-game-dev/",
  "category": "Software",
  "tags": ["gamedev", "unity", "beginners", "learning"],
  "description": "A visual dive into the things I've built, and what I've learnt in my first couple of weeks of working with Unity."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Video games have been a huge part of my life since I unwrapped my first console, the SEGA Dreamcast, on Christmas morning back when I was 5 years old. To me, they are the ultimate combination of storytelling, art, and technology.`}</p>
    <p>{`The process of developing video games became one of my earliest endeavours when I first started learning to code. Unfortunately, it was a frustrating experience akin to learning to draw without knowing how to hold a pencil, and my motivation quickly faded. At that point in time, my programming knowledge extended about as far as knowing what a `}<inlineCode parentName="p">{`for`}</inlineCode>{` loop was. After a few weeks of trying and failing to understand why almost nothing would work, I threw in the towel, feeling deflated and defeated.`}</p>
    <p>{`A year later I went to university to study Computer Science and it sparked my passion for programming outside of video game development.`}</p>
    <h2>{`Coding and creativity`}</h2>
    <p>{`Despite being an engineer and the "sciencey" type, I've always been driven by creativity. Until I was about 13 years old I couldn't decide whether I wanted to be an animator or a graphic designer, and couldn't imagine ever becoming a software engineer. How could something as technical as programming be considered enjoyable?`}</p>
    <p>{`What I realised is that coding is a largely creative process, and one that would allow me to scratch both my technical and artistic itches at once. With coding satisfying my need to express my creativity, I never found myself taking the leap back into video game development.`}</p>
    <p>{`A few weeks ago I discovered `}<a parentName="p" {...{
        "href": "https://twitter.com/SebastianLague"
      }}>{`Sebastian Lague's`}</a>{` fantastic "Coding Adventures" series on YouTube, where he explores coding concepts in `}<a parentName="p" {...{
        "href": "https://unity.com/"
      }}>{`Unity`}</a>{`, a popular video game engine. The `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=Cp5WWtMoeKg"
      }}>{`first video`}</a>{` I watched mesmerised me. In it, Sebastian uses a "ray-marching" algorithm to generate a stunning `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Mandelbulb"
      }}>{`Mandelbulb`}</a>{` fractal:`}</p>
    <p><img parentName="p" {...{
        "src": "/22f90b3a0a1bb576dc4a40471073e650/SebastianLagueMandelbulb.gif",
        "alt": "SebastianLagueMandelbulb"
      }}></img></p>
    <p>{`Although there is always creativity to be found in coding, the output of the code I typically write doesn't evoke the same feelings that a piece of art like the one above does, nor does it evoke the joy you get when you play your favourite video game. In my case, the end result is often a value being written to a database, or some data being sent over a network.`}</p>
    <p>{`I will always achieve some amount of satisfaction from these things, just like I did the first time the words `}<inlineCode parentName="p">{`"Hello, World!"`}</inlineCode>{` popped up in my terminal window. However, when the code you wrote today is similar in purpose to the code you wrote yesterday and the day before that, your vision can narrow, and you might forget that computers are capable of so much more. It's easy to forget that code can create truly beautiful things; art, in a very literal sense.`}</p>
    <h2>{`Making planets`}</h2>
    <p>{`On discovering Sebastian's series, I was inspired to pick up game development where I left off, and to prove to myself I was now capable of doing something which once left me feeling defeated. I downloaded the Unity game engine, and after watching `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=QN39W020LqU&list=PLFt_AvWsXl0cONs3T0By4puYy6GM22ko8"
      }}>{`another tutorial`}</a>{` from the same creator, decided to try my hand at procedurally generating planet surfaces.`}</p>
    <p>{`I constructed a spherical `}<strong parentName="p">{`mesh`}</strong>{` (a mesh is a collection of points joined together by triangles which together form a 3-dimensional object) using the scripting API provided by Unity. We can do this by providing Unity with a bunch of 3-dimensional vectors (called `}<strong parentName="p">{`vertices`}</strong>{`) which represent points in the world, and some integers indicating how we wish to join these points together using triangles. With these seemingly primitive tools we can create any 3-dimensional shape imaginable.`}</p>
    <p>{`To generate the mountains of the planet, I pass in each the location of each vertex to a `}<strong parentName="p">{`gradient noise`}</strong>{` function. Gradient noise has been used in computer graphics for years, for a variety of purposes, and it looks like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/1528b25c042258d1a5440c4c46a265fb/PerlinNoiseStatic.png",
        "alt": "PerlinNoiseStatic"
      }}></img></p>
    <p>{`For each pixel in the image above, imagine that the brighter it is, the greater the altitude of the pixel. So, we can think of areas of pure white as being the apex of a mountain, the grey areas represent somewhere around half way up a mountain, and the darkest areas represent the lowest elevations.`}</p>
    <p>{`Now lets "wrap" this noise around our sphere like you might wrap a basketball with wrapping paper.`}</p>
    <p><img parentName="p" {...{
        "src": "/8584ab349274b2e460d4bf65ca367ffa/PerlinNoiseSphere.png",
        "alt": "PerlinNoiseSphere"
      }}></img></p>
    <p>{`If we were to elevate the brighter points of the sphere away from the centre of the circle, we'd find ourselves with a "lumpy" sphere. We can customise the noise to increase it's density (by "zooming out" of the noise), which would result in a rougher surface. Similarly, we can zoom in to the noise to make the surface of the sphere appear smoother.`}</p>
    <p><img parentName="p" {...{
        "src": "/08a0e6463ab37138ab8ca41f3480f7db/AnimatedPerlinSphere.gif",
        "alt": "AnimatedPerlinSphere"
      }}></img></p>
    <p>{`At this point, the distribution of the noise over the sphere is entirely uniform. The surface of our "planet", much like Edinburgh, is a bit `}<em parentName="p">{`too`}</em>{` consistent when it comes to hills. So, to generate more realistic planetary surfaces, we apply the noise in several layers. One layer will model the sea; another will model land; a third will form hills; and a final rough layer of noise will sculpt the highest peaks of our planet's mountains.`}</p>
    <p>{`There's a couple of smaller additional tricks involved which Sebastian explains in the series, but the end result was a generator that created relatively realistic planetary surfaces. The image below shows what it looks like when I manipulate some of the parameters relating to how the noise is applied to the surface sphere.`}</p>
    <p><img parentName="p" {...{
        "src": "/686722cc0d00708f5231da06d39568d5/PlanetTerrainGeneration.gif",
        "alt": "PlanetTerrainGeneration"
      }}></img></p>
    <p>{`In the end, I never finished all of the tutorials in this series. Paradoxically, my motivation was so high after reaching this point that I didn't `}<em parentName="p">{`want`}</em>{` to finish the series there and then. Instead, I wanted to explore as many other areas of Unity as possible, as quickly as I could.`}</p>
    <h2>{`The dance of the planets`}</h2>
    <p>{`Next, I would simulate planetary motion. I wanted to make things a little more realistic this time, so I found myself a space `}<strong parentName="p">{`skybox`}</strong>{` on the Unity asset store. A skybox consists of six images that are placed around the player in a cubic shape, so that the player is completely surrounded by them. In this case, it means that wherever the player looks, it looks like space.`}</p>
    <p>{`Then, I created a sphere in Unity and applied an image of the surface of Mars to it (`}<a parentName="p" {...{
        "href": "https://www.solarsystemscope.com/textures/"
      }}>{`from Solar System Scope`}</a>{`). The image is called an `}<strong parentName="p">{`equirectangular projection`}</strong>{`, which means it was formed by projecting the spherical surface of Mars on to a flat surface.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9a487d4cfbeaf189b424e94af8e81738/4b190/MarsSurfaceTexture.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.306748466257666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEAf/EABYBAQEBAAAAAAAAAAAAAAAAAAEDBP/aAAwDAQACEAMQAAABVaNhrnHB/8QAGxAAAgEFAAAAAAAAAAAAAAAAAAIBEBESITL/2gAIAQEAAQUCnm7Y7Egen//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwGq/8QAGBAAAgMAAAAAAAAAAAAAAAAAADEBIJH/2gAIAQEABj8CY50dP//EABsQAQEBAAIDAAAAAAAAAAAAAAEAETFBUWGB/9oACAEBAAE/IX599MZjdm9mfYHdBgHATf/aAAwDAQACAAMAAAAQpC//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8QStm//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EBBav//EABsQAQEBAAIDAAAAAAAAAAAAAAERACFBMXGB/9oACAEBAAE/EHHH6VuUAGDyoYnynYrN/SFzxB6N5b//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "MarsSurfaceTexture",
            "title": "MarsSurfaceTexture",
            "src": "/static/9a487d4cfbeaf189b424e94af8e81738/6aca1/MarsSurfaceTexture.jpg",
            "srcSet": ["/static/9a487d4cfbeaf189b424e94af8e81738/d2f63/MarsSurfaceTexture.jpg 163w", "/static/9a487d4cfbeaf189b424e94af8e81738/c989d/MarsSurfaceTexture.jpg 325w", "/static/9a487d4cfbeaf189b424e94af8e81738/6aca1/MarsSurfaceTexture.jpg 650w", "/static/9a487d4cfbeaf189b424e94af8e81738/4b190/MarsSurfaceTexture.jpg 800w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`I wrote a script and attached it to the planet in order to have it apply a pulling force to things around it, based on the mass of the planet. You know, gravity. I placed a few of these "planets" around the scene, then added a glowing effect around them for no reason other than I had just discovered it was possible and I thought it looked pretty cool.`}</p>
    <p>{`With the scene set, I sat back, and prepared for the elegance that is the dance of the planets.`}</p>
    <p>{`Here's what happened...`}</p>
    <p><img parentName="p" {...{
        "src": "/afac646238dfc7764c1621e6e8f827af/gravity1.gif",
        "alt": "gravity1"
      }}></img></p>
    <p>{`Then I remembered the fundamental law of gravity which states that when the apple drops from the tree it falls to the ground. The apple does `}<em parentName="p">{`not`}</em>{` drop from the tree and immediately begin orbiting the planet. With the realisation that this dance was far too dependent on the choreographer's physics knowledge, I decided to beam off to my next adventure.`}</p>
    <h2>{`Interactivity`}</h2>
    <p>{`Both of these experiments were really enjoyable and gave me a feel for working with Unity, but neither were "games". Without interactivity, they're really just simulations. `}</p>
    <p>{`Despite the fact that it was basically an advert for why NASA should never hire me, I enjoyed the "feel" of the previous scene. I decided to keep things in space. This also meant that I didn't have to fill my scene with assets in order to achieve a sense of realism. After all, space is mostly empty.`}</p>
    <p>{`I wanted to build in some mechanics from real-time strategy (RTS) games. Although I didn't have a goal in mind for where I wanted my game to go at the time, implementing interesting game mechanics is great experience whether you end up using them or not. I'd learn the concepts behind creating the mechanics, and find out what works, and what doesn't work.`}</p>
    <p>{`I started by creating a cube which would represent a space ship. Then I added the ability to click the cube in order to select it. This involved learning about `}<strong parentName="p">{`raycasting`}</strong>{`, where you fire off a ray in a direction, and check what it collides with. In order to implement unit selection, I had to send a ray out from the camera, through the cursor position, and detect whether the ray collided with my cube. Then, with the cube selected, I made it so you could right click to have the cube travel to a point in space after rotating to face the correct direction.`}</p>
    <p><img parentName="p" {...{
        "src": "/cee14332a4c40fccdc5ec6a10f3a4427/CubeInSpace.png",
        "alt": "CubeInSpace"
      }}></img></p>
    <p>{`After getting movement down, I upgraded my cube to a `}<a parentName="p" {...{
        "href": "https://assetstore.unity.com/packages/3d/luminaris-starship-71439"
      }}>{`cool ship asset`}</a>{` I found on the `}<a parentName="p" {...{
        "href": "https://assetstore.unity.com/"
      }}>{`Unity Asset Store`}</a>{`, and added the ability to pan, zoom and rotate the camera. It took me almost two hours to figure out how to make my ship not fly with its nose pointing down. After some research I found that this was happening because the 3D modelling software Blender uses a different coordinate system from Unity. The axis that the ship model perceived to be "forward", was Unity's idea of "down". To fix the problem, I placed the ship model inside an empty Unity GameObject, adjusted it so that it was facing forward (for real this time), and applied all future rotations to the parent instead of to the ship directly.`}</p>
    <p>{`I wrote a small script called `}<inlineCode parentName="p">{`Rotator`}</inlineCode>{` to make planets slowly rotate by slightly increasing the rotation on every physics update, which made the scene feel less static.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-c#"
      }}>{`public class Rotator : MonoBehaviour {
    void FixedUpdate() {
        transform.Rotate(Vector3.up, .01f);
    }
}
`}</code></pre>
    <h2>{`Box selection`}</h2>
    <p>{`Most top down strategy games allow you to select multiple units at once by dragging a rectangle around them. I used the skills I learned from the planet generation tutorial to dynamically construct a plane as you click and drag the mouse. I applied a `}<strong parentName="p">{`shader`}</strong>{` to the mesh that is generated to make it slightly colourful, and a little transparent. A shader is a concept in computer graphics which lets you apply special effects to an object using your GPU, making them really efficient.`}</p>
    <p>{`Shaders are usually written using a special scripting language, which looks a bit daunting to learn. However, In Unity, you can build shaders using an amazing visual tool called Shader Graph, which is so much fun to use and something I definitely want to dive deeper into in the future.`}</p>
    <p><img parentName="p" {...{
        "src": "/2142131963d5663aab8bd0c8825d69c2/ShaderGraph.png",
        "alt": "ShaderGraph"
      }}></img></p>
    <p>{`After implementing this, I realised that performing the selection in 3-dimensional space can be a little tricky, especially if you have multiple units on screen and if the camera has been rotated at all. It can be hard for the brain to quickly understand the rotation the camera is currently at, and to know the angles that the edges of the box will appear at when you start dragging.`}</p>
    <p><img parentName="p" {...{
        "src": "/5a4b3c91218ed5bae2b83c131fab25e1/DragSelectFinal.gif",
        "alt": "DragSelectFinal"
      }}></img></p>
    <p>{`In the future, I may update my rectangular selection code so that you draw the rectangle in 2-dimensional space, and project it onto the 3-dimensional space of the game world. This is how it works in games like StarCraft 2, and it `}<em parentName="p">{`definitely`}</em>{` feels more intuitive.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "576px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fe339adf9bf60b85ff1841105e26fd33/533c1/StarcraftBoxSelect.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44171779141104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAC4klEQVQozx3JaVMaBwCA4bWkhmjCpNUx2lKsGjHRqNzLIbJysxzK5VbRSoIBDQpFEBbEKzUmtmmSdjrTn/t22m/PzCN43SM4zDoiMQvd5jq1wyCtRpCrfprb6zzdlp/TZph3vx7z8b2Kf8WGYeIbRJORTHaNYiXEXnmN7eIqsU0ngtn7LdNPdcTiIkflMJ2mn7sPBT5/qaCe5yiW3DRPFP781KGwE+epaZxZx/f4V0xkUy5OjqL01RTddpryaz+CnJrjorvP2/4u768UOp0NDk/znP+zi9KPk2hIZFp2dtoi8WMP0qGdqawee2IGWRZp1VOctWWuLhQazRTCX3+0+Hhb4bqb5e5mi/PuNuWjHKd/r9O6tJDZmyXdmEfpW7Dt2jAXRGz7kxjXJ7AuTlLaiXJynOSil+PmpoDw7nKHXjuJqqZRewpXF1t0bwqUb4McNpZYL87hOTCR63tYO3ayWi8Srpl5tjnCssNAKR/h1V6Ko4rMmZpBOCoHUU9iqJ0onV+idGsyam+Dl7+H2L+VSKsiedWN/GaZYNVDoJYnWbfjLk3iCiwQdi7gtRmRxGesxxwIymaMVwU/tWqSRkWiVJJpXypUP4Wp3AXJ9b3EGhaUlki2U0FWCxSvnSSqdhYXppjRjyIuTeO2GvFZ5xASfj97GR/FrI96UUJtBek0QjQ+yLz+TSF3XWX7LEC+lyZ+ukOkPE+iamE5o8cwrUNyLRLwLON48QOSZwXBbbOT34oS8LnIRZJsF9JspEJsZFO8OShQqR9QbRzy0+4GckoiFPUh+a2IoVFcq5MosShxrxXn8yncJjOCd8XKz51NQpshHJYQg9qHDGqGGZwaZygyh+7BY0bH9DzUjaG9/4gHWh1D2iHClnmUVBb9jy/QDg0jCBrG9IsIluXn5EsJ0i8jiEvz3Ls3wIAgoHkyzNfmJwiCgGHGyKORif/93w0MCCyMPyZiWWRi1oTmKw33tVr03xn4F/yPmp91KwuGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "StarcraftBoxSelect",
            "title": "StarcraftBoxSelect",
            "src": "/static/fe339adf9bf60b85ff1841105e26fd33/533c1/StarcraftBoxSelect.png",
            "srcSet": ["/static/fe339adf9bf60b85ff1841105e26fd33/222b7/StarcraftBoxSelect.png 163w", "/static/fe339adf9bf60b85ff1841105e26fd33/ff46a/StarcraftBoxSelect.png 325w", "/static/fe339adf9bf60b85ff1841105e26fd33/533c1/StarcraftBoxSelect.png 576w"],
            "sizes": "(max-width: 576px) 100vw, 576px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Right now it's also not clear which units you currently have selected. In the future I'll decide how I want to convey this information to the player. It's a tricky problem, especially with the potential for many units to appear on screen at once.`}</p>
    <h2>{`Combat`}</h2>
    <p>{`A game with space ships `}<em parentName="p">{`obviously`}</em>{` requires that they have the ability to unleash a barrage of missiles at each other!  I wrote a script called `}<inlineCode parentName="p">{`BasicAttack`}</inlineCode>{` and attached it to my ship prefab. This script defines things like "how much damage do my attacks do". At first, attacks were performed using raycasting. An invisible ray was fired out from the selected ship towards the direction of the mouse click. If the ray collided with another ship, it immediately deducted the attack damage of the attacking ship from the hitpoints of the ship that took damage. If the hitpoints of a ship falls to zero or below, it gets immediately removed from the world.`}</p>
    <p>{`Without any visual feedback, this made for a rather dull experience. So I added a few more features in order to liven things up a little.`}</p>
    <p>{`I attached a `}<inlineCode parentName="p">{`LineRenderer`}</inlineCode>{` (used to draw lines, and comes with Unity) component to my ship `}<strong parentName="p">{`prefab`}</strong>{`. A prefab in Unity is similar to a class in programming. It's defines a blue print for what an instance of it will look like, and can easily be reused anywhere. The start point of the line is set to be the centre of the selected ship, and the endpoint is set to the position of the cursor. If a ship is selected, and the user presses `}<inlineCode parentName="p">{`A`}</inlineCode>{`, the ship will enter "Attack Mode", and the `}<inlineCode parentName="p">{`LineRenderer`}</inlineCode>{` will become visible on screen. I created a shader using Shader Graph and applied it to the line to make it slowly fade in and out over time, just to make things a little more visually interesting.`}</p>
    <p><img parentName="p" {...{
        "src": "/8ae38ecd7018b83d23d8ba96904138d6/FlashingRedShader.gif",
        "alt": "FlashingRedShader"
      }}></img></p>
    <p>{`Then, I updated the `}<inlineCode parentName="p">{`BasicAttack`}</inlineCode>{` code to spawn a missile instead of casting an invisible ray. The missile is just a capsule, with a `}<inlineCode parentName="p">{`TrailRenderer`}</inlineCode>{` (built in to Unity) attached to it, which results in a trail of light which follows the missile as it travels towards its target. For now, the missiles always travel at a speed such that it takes 3 seconds to reach their target. After 3 seconds, the target takes damage. This isn't the most realistic approach, but it works.`}</p>
    <p><img parentName="p" {...{
        "src": "/b59781092ab71c2c250f8535629d4ff7/MissileCapsule.png",
        "alt": "MissileCapsule"
      }}></img></p>
    <p>{`To let the player know how much damage the target has taken, I implemented (after `}<em parentName="p">{`hours`}</em>{` of headaches) floating damage numbers above the target. I absolutely `}<em parentName="p">{`love`}</em>{` this effect in video games. When a target takes damage, the amount of damage it's taken briefly appears above the unit. The size of the font also dynamically scales based on the damage taken. `}</p>
    <p>{`In my implementation, for damage numbers between 1 and 50, the standard font size is used (it results in a `}<inlineCode parentName="p">{`fontScaleFactor`}</inlineCode>{` of 1). As we start doing more than 50 damage, the font size used to display the damage numbers increases in size, up to a maximum damage of 300. If an attack applies 300 or more damage, the font size will be double the standard size.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`var fontScaleFactor = 1 + Mathf.InverseLerp(50, 300, damage);
damageText.fontSize *= fontScaleFactor;
`}</code></pre>
    <p>{`Since a ship can take multiple damage instances in quick succession, I added some random variation to the position these damage numbers spawn at. Without this variation, the numbers overlap perfectly, and it isn't possible to determine how many instances of damage the ship has received. Here's the function I wrote to apply the randomness to the spawn position of the floating damage numbers:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`private Vector3 ApplyRandomOffsets(Vector3 vec, float minOffset, float maxOffset) {
    var xOff = Random.Range(minOffset, maxOffset);
    var yOff = Random.Range(minOffset, maxOffset);
    var zOff = Random.Range(minOffset, maxOffset);
    return new Vector3(vec.x + xOff, vec.y + yOff, vec.z + zOff);
}
`}</code></pre>
    <p>{`With these changes, things became a little bit more visually exciting:`}</p>
    <p><img parentName="p" {...{
        "src": "/5450097d773a9abd5314fb6eed0bf5e7/AimAndFireFinal.gif",
        "alt": "AimAndFireFinal"
      }}></img></p>
    <h2>{`Explosions`}</h2>
    <p>{`To give the player more visual feedback that that their attacks do damage, I added explosions that trigger when the missiles reach their target. Explosions in Unity are usually achieved using the Unity Particle System, which lets you create amazing physics based particle effects. Rather than attempting to create my own textures and particle systems, I used some of the particle systems available in the `}<a parentName="p" {...{
        "href": "https://assetstore.unity.com/packages/essentials/tutorial-projects/unity-particle-pack-127325"
      }}>{`Unity Particle Pack`}</a>{`.`}</p>
    <p>{`My initial attempts at making things explode didn't really go to plan.`}</p>
    <p><img parentName="p" {...{
        "src": "/a317f4de42fb6580e3b2355aea45ff59/ExplosionsWithoutMaterial.gif",
        "alt": "ExplosionsWithoutMaterial"
      }}></img></p>
    <p>{`After some messing around, I upgraded my weird pink box shapes and arrived at something which looked a bit like a firework. Not exactly the high impact effect I was looking for, but certainly a step in the right direction.`}</p>
    <p><img parentName="p" {...{
        "src": "/b067b8b45284da1dad77281e48322931/ExplosionsConvertedForLWRP.gif",
        "alt": "ExplosionsConvertedForLWRP"
      }}></img></p>
    <p>{`A bit of Googling and some more messing around with settings, and I finally realised the cause of my problems was my use of Unity's newish Lightweight Render Pipeline (LWRP). The shaders used by the explosion weren't supported. After updating them to ones that are supported by the LWRP, the particle system actually started looking like a realistic explosion:`}</p>
    <p><img parentName="p" {...{
        "src": "/8e2038fe53c552fd30e97a47cf76312a/ExplosionsConvertedForLWRPShaderChangedToStandardLit.gif",
        "alt": "ExplosionsConvertedForLWRPShaderChangedToStandardLit"
      }}></img></p>
    <p>{`When the missiles reach their targets, the target will play an explosion effect. As an extra challenge, I gave each ship "shields", which is an additional layer of hitpoints. If a missile strikes a ship and it still has shields, the explosion is blue. After a ship runs out of shield energy, the explosions become orange as a visual cue to the player that the ship is low on total health.`}</p>
    <p><img parentName="p" {...{
        "src": "/df1cbd66df18cba259b6f391037bbb1f/DifferentExplosionsForShieldHits.gif",
        "alt": "DifferentExplosionsForShieldHits"
      }}></img></p>
    <p>{`I still have lots of ideas about how I could improve the effects here. Randomly varying the missile strike locations, and making the explosion effects spawn at those locations will add to the realism. Instead of hardcoding the explosion to appear at the centre of the target ship 3 seconds after the missile is fired, moving to a collision based system will allow the missile to explode immediately upon striking its target. A blue explosion isn't how "shield" effects are typically portrayed in films or video games either, so a shader which simulates a "force-field" effect might work better.`}</p>
    <h2>{`Moving forward`}</h2>
    <p>{`There's still lots to learn, but I'm really happy with my progress over the course of the past couple of weeks. Although there's no real gameplay in the project beyond "fly around and attack ships", it was an incredible learning experience and one that still makes me feel like I accomplished my goal of learning to create video games.`}</p>
    <p>{`Going forward, I'd like to learn how to add a UI to the project above, and perhaps implement some interesting gameplay systems. I have a bunch of notes on how gameplay might work, and some ideas I want to experiment with. I may also move onto other projects, such as implementing interesting game mechanics I've experienced from other games, like the portal gun from `}<em parentName="p">{`Portal`}</em>{`, or the Leviathan Axe from `}<em parentName="p">{`God of War`}</em>{`.`}</p>
    <p>{`Either way, learning the basics of Unity has opened up a whole new world of creativity for me. There's now so many things I now want to explore and learn to create, and I now have the confidence in myself that I'll be able to succeed at learning them!`}</p>
    <p>{`In the future I might create more development/learning logs like these discussing the things I've built, and what I learned in the process. If you're interested, follow me on `}<a parentName="p" {...{
        "href": "https://dev.to/_darrenburns"
      }}>{`DEV`}</a>{`, `}<a parentName="p" {...{
        "href": "https://twitter.com/_darrenburns"
      }}>{`Twitter`}</a>{`, and check out my personal blog at `}<a parentName="p" {...{
        "href": "https://darrenburns.net"
      }}>{`darrenburns.net`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      